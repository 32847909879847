import React, { useState, useEffect } from 'react';

import {
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  Box,
  Drawer,
  Divider,
  Grid,
  Typography,
  Switch,
} from '@mui/material';

import Cookie from 'js-cookie';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import { useTheme } from '@mui/material/styles';

const drawerWidth = 450;

const MuiAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const MuiAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ExpandCircleDownIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// ==============================|| SAMPLE PAGE ||============================== //

export default function GetUniversalCookies() {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [parameters, setParameters] = useState(false);
  const [expanded, setExpanded] = useState('panel1');

  const [__CK_politics       , set__CK_politics]        = useState(false);
  const [__CK_cookieOwn      , set__CK_cookieOwn]       = useState(true);
  const [__CK_cookieClarity  , set__CK_cookieClarity]   = useState(false);
  const [__CK_cookiePixel    , set__CK_cookiePixel]     = useState(false);
  const [__CK_cookieAnalytics, set__CK_cookieAnalytics] = useState(false);

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {

    /*
    console.log("**************************************************************");
    console.log("__CK_politics => ", Cookie.get("__CK_politics"));
    console.log("**************************************************************");
    */

    if( Cookie.get("__CK_politics") === undefined || !Cookie.get("__CK_politics")) {
      // NO HAY COOKIES CREADAS o LA POLÍTICA ESTA EN FALSO
      setOpen(true);
    } else {
      // HAY COOKIES CREADAS o LA POLÍTICA ESTA EN VERDADERO

      if( Cookie.get("__CK_cookieClarity")) {

      }
    }
  }, []);

  const handleAcceptButton  = () => {
    set__CK_politics(true);
    set__CK_cookieOwn(true);
    set__CK_cookieClarity(true);
    set__CK_cookiePixel(true);
    set__CK_cookieAnalytics(true);

    /*
    console.log("**********************************************");
    console.log("handleAcceptButton");
    console.log("__CK_politics"       , __CK_politics);
    console.log("__CK_cookieOwn"      , __CK_cookieOwn);
    console.log("__CK_cookieClarity"  , __CK_cookieClarity);
    console.log("__CK_cookiePixel"    , __CK_cookiePixel);
    console.log("__CK_cookieAnalytics", __CK_cookieAnalytics);
    console.log("**********************************************");
    */
    /*
    Cookie.set("__CK_politics"       , __CK_politics, { expires: 365, path: '' });
    Cookie.set("__CK_cookieOwn"      , __CK_cookieOwn, { expires: 365, path: '' });
    Cookie.set("__CK_cookieClarity"  , __CK_cookieClarity, { expires: 365, path: '' });
    Cookie.set("__CK_cookiePixel"    , __CK_cookiePixel, { expires: 365, path: '' });
    Cookie.set("__CK_cookieAnalytics", __CK_cookieAnalytics, { expires: 365, path: '' });
    */
    Cookie.set("__CK_politics"       , true, { expires: 365, path: '' });
    Cookie.set("__CK_cookieOwn"      , true, { expires: 365, path: '' });
    Cookie.set("__CK_cookieClarity"  , true, { expires: 365, path: '' });
    Cookie.set("__CK_cookiePixel"    , true, { expires: 365, path: '' });
    Cookie.set("__CK_cookieAnalytics", true, { expires: 365, path: '' });
    setOpen(false);
    setParameters(false);
  }
  
  const handleAcceptSelectionButton  = () => {
    set__CK_politics(true);

    Cookie.set("__CK_politics"       , true, { expires: 365, path: '' });
    Cookie.set("__CK_cookieOwn"      , __CK_cookieOwn, { expires: 365, path: '' });
    Cookie.set("__CK_cookieClarity"  , __CK_cookieClarity, { expires: 365, path: '' });
    Cookie.set("__CK_cookiePixel"    , __CK_cookiePixel, { expires: 365, path: '' });
    Cookie.set("__CK_cookieAnalytics", __CK_cookieAnalytics, { expires: 365, path: '' });
    setOpen(false);
    setParameters(false);
  }

  const handleDeclineButton  = () => {
    set__CK_politics(true);
    set__CK_cookieOwn(true);
    set__CK_cookieClarity(false);
    set__CK_cookiePixel(false);
    set__CK_cookieAnalytics(false);
    /*
    Cookie.set("__CK_politics"       , __CK_politics, { expires: 365, path: '' });
    Cookie.set("__CK_cookieOwn"      , __CK_cookieOwn, { expires: 365, path: '' });
    Cookie.set("__CK_cookieClarity"  , __CK_cookieClarity, { expires: 365, path: '' });
    Cookie.set("__CK_cookiePixel"    , __CK_cookiePixel, { expires: 365, path: '' });
    Cookie.set("__CK_cookieAnalytics", __CK_cookieAnalytics, { expires: 365, path: '' });
    */
    Cookie.set("__CK_politics"       , true, { expires: 365, path: '' });
    Cookie.set("__CK_cookieOwn"      , true, { expires: 365, path: '' });
    Cookie.set("__CK_cookieClarity"  , false, { expires: 365, path: '' });
    Cookie.set("__CK_cookiePixel"    , false, { expires: 365, path: '' });
    Cookie.set("__CK_cookieAnalytics", false, { expires: 365, path: '' });

    setOpen(false);
    setParameters(false);
  }
  

  const handleCloseParametersButton  = () => {
    setParameters(false);
  }

  const handleOpenParametersButton  = () => {
    setParameters(true);
  }
  const handleChangeSwitch = (event, cookie) => {
    switch (cookie) {
      case "own": 
        set__CK_cookieOwn(event.target.checked);
        break;
      case "clarity": 
        set__CK_cookieClarity(event.target.checked);
        break;
      case "pixel": 
        set__CK_cookiePixel(event.target.checked);
        break;
      case "analytics": 
        set__CK_cookieAnalytics(event.target.checked);
        break;
      default:
    }
  }
  return (
    <>
        <Drawer
          sx={{
              flexGrow: 1,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
              width: "100%",
              }
          }}
          variant="persistent"
          anchor="bottom"
          open={ open }
        >
          <Box sx={{ flexGrow: 1, m: theme.spacing(4) }}>
            <Grid container>
              <Grid item xs={8} sm={9}>
                  <Typography variant="h6" gutterBottom>Utilizamos cookies</Typography>          
                  <Typography variant="subtitle1" gutterBottom color={"grey"}>Este sitio web utiliza cookies propias y de terceros para mantener la sesión, ofrecerle una mejor experiencia de usuario y obtener datos estadísticos de navegación de los usuarios. Para más información vea la Política de cookies.</Typography>          
              </Grid>
              <Grid item xs={4} sm={3}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Button variant="outlined" fullWidth  sx={{ m: theme.spacing(1) }} onClick={ handleOpenParametersButton }>Gestión de cookies</Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Button variant="outlined" fullWidth  sx={{ m: theme.spacing(1) }} onClick={ handleDeclineButton }>Rechazar todas</Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="contained" fullWidth  sx={{ m: theme.spacing(1) }} onClick={ handleAcceptButton }>Aceptar todas</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Drawer>

        <Drawer
          sx={{
              flexGrow: 1,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
              width: drawerWidth,
              },
          }}
          
          variant="persistent"
          anchor="right"
          open={ parameters }
        >
          <Box sx={{ flexGrow: 1, m: theme.spacing(4) }}>
            <Grid container>
              <Grid item xs={12} sm={12} sx={{ m: theme.spacing(1) }} >
                <Grid container>
                  <Grid item xs={11} sm={11}>
                    <Typography variant="h6" gutterBottom>Gestión de cookies</Typography>          
                  </Grid>
                  <Grid item xs={11} sm={1}>
                    <Button variant="conteined" fullWidth onClick={ () => handleCloseParametersButton() }><CloseOutlinedIcon /></Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider/>
                  <Typography variant="subtitle1" gutterBottom color={"grey"}>Este sitio web utiliza cookies propias y de terceros para mantener la sesión, ofrecerle una mejor experiencia de usuario y obtener datos estadísticos de navegación de los usuarios. Para más información vea la Política de cookies.</Typography>          
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} sx={{ m: theme.spacing(1) }}>
                <MuiAccordion onChange={handleChangeAccordion('panel1')}>
                  <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Cookies para el funcionamiento de la web</Typography>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                    <Typography variant="caption" display="block" gutterBottom>
                      Estas cookies son esenciales para el correcto funcionamiento del sitio web, sin estas cookies el sitio web puede no funcionar correctamente. Cookies propias y de protección de Google reCAPTCHA v3.
                    </Typography>
                    <AccordionActions>
                      <Switch 
                        disabled 
                        checked = { __CK_cookieOwn } 
                        onChange={ (event) => handleChangeSwitch(event, "own") }
                      />
                  </AccordionActions>                  
                  </MuiAccordionDetails>
                </MuiAccordion>
                <MuiAccordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                  <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Cookies Microsoft Clarity</Typography>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                    <Typography variant="caption" display="block" gutterBottom>
                    El código de seguimiento de Clarity utiliza una cookie para obtener datos de la sesión del usuario. Una cookie es un pequeño archivo que se almacena en el navegador y que nos indica cómo ha accedido un usuario a su sitio, su ubicación, su preferencia de idioma y más.
                    </Typography>
                  </MuiAccordionDetails>
                  <AccordionActions>
                      <Switch 
                        checked={ __CK_cookieClarity }
                        onChange={ (event) => handleChangeSwitch(event, "clarity") }
                      />
                  </AccordionActions>                  
                </MuiAccordion>
                <MuiAccordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                  <MuiAccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Cookies Pixel de Meta</Typography>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                    <Typography variant="caption" display="block" gutterBottom>
                      Se utilizan para generar un identificador de usuario único, utilizado para hacer recuento de cuántas veces visita el sitio un usuario, así como la fecha de la primera y la última vez que visitó la web. Registran la fecha y hora de acceso a cualquiera de las páginas del portal. Comprueban la necesidad de mantener la sesión de un usuario abierta o crear una nueva. Identifican la sesión del usuario, para recoger la ubicación geográfica aproximada del ordenador que accede al portal.
                    </Typography>
                  </MuiAccordionDetails>
                  <AccordionActions>
                    <Switch 
                      checked={ __CK_cookiePixel }
                      onChange={ (event) => handleChangeSwitch(event, "pixel") }
                    />
                  </AccordionActions>                  
                </MuiAccordion>
                <MuiAccordion expanded={expanded === 'panel4'} onChange={handleChangeAccordion('panel4')}>
                  <MuiAccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Cookies de Google Analytics</Typography>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                    <Typography variant="caption" display="block" gutterBottom>
                    Las cookies ayudan a aumentar la eficacia de la publicidad. Sin las cookies, resulta más difícil que un anunciante llegue a su audiencia o saber cuántos anuncios se han mostrado o cuántos clics han recibido esos anuncios.
                    </Typography>
                  </MuiAccordionDetails>
                  <AccordionActions>
                    <Switch 
                      checked={ __CK_cookieAnalytics }
                      onChange={ (event) => handleChangeSwitch(event, "analytics") }
                    />
                  </AccordionActions>                  
                </MuiAccordion>
              </Grid>

              <Grid item xs={12} sm={12}>
                  <Typography variant="h6" gutterBottom>Más información</Typography>          
                  <Divider/>
                  <Typography variant="subtitle1" gutterBottom color={"grey"}>Al pulsar el botón "Aceptar selección" se están aceptando las cookies seleccionadas, además de las cookies técnicas que permiten un correcto funcionamiento del sitio web. Al pulsar el botón "Rechazar todas" se están rechazando todas las cookies, excepto las cookies técnicas que permiten un correcto funcionamiento del sitio web.</Typography>          
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Button variant="contained" fullWidth  sx={{ m: theme.spacing(1) }} onClick={ () => handleAcceptButton() }>Aceptar todas</Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Button variant="outlined" fullWidth  sx={{ m: theme.spacing(1) }} onClick={ () => handleDeclineButton() }>Rechazar todas</Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="outlined" fullWidth  sx={{ m: theme.spacing(1) }} onClick={ () => handleAcceptSelectionButton() }>Aceptar selección</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
      </Drawer>
    </>
  );
}
