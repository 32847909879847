import React from 'react';

import GetCookies from '../components/GetCookies';

const Cookies = () => {
  return (
    <>
      <GetCookies />
    </>
  )
}

export default Cookies