import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";

import { 
    Card,
    CardMedia,
    Link
} from "@mui/material";

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';
import { useEffect, useState } from "react";

const Slide = ({slide}) => {

  if (typeof slide.link !== "string") return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height={ ( window.innerWidth < 750 ? 700 : 400 ) }
        image= { ( window.innerWidth < 750 ? slide.image_portrait : slide.image_landscape ) }
      />
    </Card>
  );

  if (slide.link.length > 0) return (
    <Link href={slide.link} target="_blank">
      <Card sx={{ maxWidth: "100%" }}>
        <CardMedia
          component="img"
          alt="green iguana"
          height={ ( window.innerWidth < 750 ? 700 : 400 ) }
          image= { ( window.innerWidth < 750 ? slide.image_portrait : slide.image_landscape ) }
        />
      </Card>
    </Link>
  );
  if (slide.link.length === 0) return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height={ ( window.innerWidth < 750 ? 700 : 400 ) }
        image= { ( window.innerWidth < 750 ? slide.image_portrait : slide.image_landscape ) }
      />
    </Card>
  );

}
export default function Slider() {
    const [slides, setSlides] = useState([]);

    const ADVERTINSINGSLIDER_CompleteList = async () => {
        try {
        const response = await SiteService.ADVERTINSINGSLIDER_CompleteList();
        setSlides(response.datos.data);
        } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
        }
    }

    useEffect(() => {
        ADVERTINSINGSLIDER_CompleteList();
    }, [])

  return (
    <ReactCaroussel 
    slidesToShow={1} 
    slidesToScroll={1} 
    infinite={true}
    autoplay={true}
    speed={4} // 2s
    display={{ 
        arrows: true, 
        dots: false 
        }}        
    >

    {slides.map((slide) => (
        <Slide key={slide.id} slide={slide} />
        ))}
    </ReactCaroussel>
  );
}
