import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';

//  third party
import * as Yup from 'yup';
import { Formik } from 'formik';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../../../services/SiteService';

// ==============================|| FIREBASE LOGIN ||============================== //

const SendMailForm = ({ data, onClose }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [alerta, setAlerta] = useState({
    tipo: 'error',
    mensaje: ''
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSendMailForm = () => {

    onClose(true);
  };

  return (
    <>
      <Formik
        initialValues={{
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          subject: data.subject,
          content: data.content,
          receiver: data.receiver
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Debe ser un correo electrónico válido').max(255).required('Correo electronico es requerido'),
          first_name: Yup.string().max(255).required('Se requiere un nombre'),
          last_name: Yup.string().max(255).required('Se requiere un apellido'),
          phone:  Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Debe ser un Teléfono Celular válido').required('Se requiere el Teléfono Celular'),
        })}
        onSubmit={ (values) => {
          try {
            SiteService.SENDMAIL({
                type: "property",
                email: values.email,
                name: values.first_name + " " + values.last_name,
                phone: values.phone,
                subject: values.subject,
                content: values.content,
                receiver: values.receiver
            });
    
            SiteService.OPERATIONLOG_Register({
                type: "property",
                action: "email",
                associated_id: data.property_id,
                object: JSON.stringify({
                    email: values.email,
                    name: values.first_name + " " + values.last_name,
                    phone: values.phone,
                    subject: values.subject,
                    content: values.content,
                    receiver: values.receiver
                })
            });

            handleCloseSendMailForm();

          } catch (err) {
            setAlerta({
              tipo: "error",
              mensaje: "La cuenta de correo ya está registrada en la plataforma."
            })
            setOpen(true);
          }

        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>

            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Dirección correo"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.first_name && errors.first_name)}
              fullWidth
              helperText={touched.first_name && errors.first_name}
              label="Nombre"
              margin="normal"
              name="first_name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.first_name}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.last_name && errors.last_name)}
              fullWidth
              helperText={touched.last_name && errors.last_name}
              label="Apellido"
              margin="normal"
              name="last_name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.last_name}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              helperText={touched.phone && errors.phone}
              label="Teléfono"
              margin="normal"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.phone}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.content && errors.content)}
              fullWidth
              helperText={touched.content && errors.content}
              label="Mensaje"
              margin="normal"
              name="content"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              multiline
              maxRows={4}              
              value={values.content}
              variant="outlined"
            />

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box mt={2}>
              <Button color="primary" disabled={isSubmitting } fullWidth size="large" type="submit" variant="contained">
                Enviar
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alerta.tipo}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerta.mensaje}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SendMailForm;
