import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { gridSpacing } from '../../../config';
import { useTheme, styled } from '@mui/material/styles';

import { useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import { store } from '../../../store';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../../../services/SiteService';

/* DATOS PARA EL FORMULARIO */
import { formikValidationSchema, formikLabel } from './initialFormik';

export default function Criteria(props) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const {isChangeResult, setIsChangeResult} = props;

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [alerta, setAlerta] = React.useState({
    tipo: 'error',
    mensaje: ''
  });

  /* VARIABLES GENERALES DEL FORMULARIO */
  const [countries, setCountries] = useState([{id: 0, name: "(Todos)", currency: '', currency_symbol: ''}]);
  const [states, setStates] = useState([{id: 0, name: "(Todos)"}]);
  const [cities, setCities] = useState([{id: 0, name: "(Todas)"}]);
  const [materials, setMaterials] = useState([{id: 0, name: "(Todos)"}]);
  const [commercialOperations, setCommercialOperations] = useState([{id: 0, name: "(Todas)"}]);
  
  const search = store.getState().currentSession.search;

  const [ formikInitialData, setFormikInitialData ] = React.useState({
    country_id: search.country_id,
    state_id: search.state_id,
    city_id: search.city_id,
    material_id: search.material_id,
    commercialoperation_id: search.commercialoperation_id,
    m3_min: search.m3_min,
    m3_max: search.m3_max,
    price_min: search.price_min,
    price_max: search.price_min,
    years_min: search.years_min,
    years_max: search.years_max
  });


  /* FORMIK: DEFINICION */
  const label = formikLabel;
  const {handleSubmit, handleChange, values, errors, touched, isSubmitting, resetForm} = useFormik({
    initialValues: formikInitialData,
    validationSchema: formikValidationSchema,
    enableReinitialize: true,
    onSubmit: ( data, actions ) => { onSubmitData(data, actions) }
  });


  //    onSubmit: ( data ) => { onSubmitData(data) }

  const onSubmitData = (data, actions) => {

    dispatch({ type: actionTypes.SET_SEARCH, search: {
      country_id: data.country_id,
      state_id: data.state_id,
      city_id: data.city_id,
      material_id: data.material_id,
      commercialoperation_id: data.commercialoperation_id,
      m3_min: data.m3_min,
      m3_max: data.m3_max,
      price_min: data.price_min,
      price_max: data.price_max,
      years_min: data.years_min,
      years_max: data.years_max
    } });

    try {
      SiteService.OPERATIONLOG_Register({
          type: "critery",
          action: "filter",
          country_id: data.country_id,
          state_id: data.state_id,
          city_id: data.city_id,
          material_id: data.material_id,
          total_mts3_min: data.m3_min,
          total_mts3_max: data.m3_max,
          construction_min: data.years_min,
          construction_max: data.years_max,
          commercialoperation_id: data.commercialoperation_id,
          price_min: data.price_min,
          price_max: data.price_max
        });
    } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
    }

    actions.setSubmitting(false);
    setIsChangeResult(true);
  }

  const handleClean = () => {

    dispatch({ type: actionTypes.SET_SEARCH, search: {
      country_id: 207,
      state_id: 0,
      city_id: 0,
      material_id: 0,
      commercialoperation_id: 0,
      m3_min: 0,
      m3_max: 0,
      price_min: 0,
      price_max: 0,
      years_min: 0,
      years_max: 0
    } });
    resetForm({ values: {
      country_id: 207,
      state_id: 0,
      city_id: 0,
      material_id: 0,
      commercialoperation_id: 0,
      m3_min: 0,
      m3_max: 0,
      price_min: 0,
      price_max: 0,
      years_min: 0,
      years_max: 0
    } });
    setIsChangeResult(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const COUNTRY_List = async () => {
    try {
      const response = await SiteService.COUNTRY_List("(Todos)");
      setCountries(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }

  const STATE_listByCountry = async (country_id) => {
    try {
      const response = await SiteService.STATE_ListByCountry(country_id);
      setStates(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }

  const CITY_listByCountryAndState = async (country_id, state_id) => {
    try {
      const response = await SiteService.CITY_ListByCountryAndState(country_id, state_id);
      setCities(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }
  }

  const MATERIAL_List = async () => {
    try {
      const response = await SiteService.MATERIAL_List();
      setMaterials(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }

  const COMMERCIALOPERATION_List = async () => {
    try {
      const response = await SiteService.COMMERCIALOPERATION_List("(Todas)");
      setCommercialOperations(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }


  /* SE EJECUTA CUANDO  DETECTA EL CAMBIO DE PAIS = values.country_id */
  /*
  useEffect(() => {
    //if (!isLoading) {

      console.log("**************************************");
      console.log("values.country_id => ", values.country_id);
      console.log("**************************************");

      if (values.country_id > 0) {
        const item = countries.find(item => item.id === values.country_id);
        values.currency = item.currency;

        STATE_listByCountry(values.country_id);

      } else {
        values.state_id = 0;
        values.city_id = 0;
        values.currency = '';
    
        setStates([
          {id: 0, name: "(Todos)"},
        ]);
    
        setCities([
          {id: 0, name: "(Todas)"},
        ]);
    
      }
    //}
  }, [values.country_id]);
  */

  /* SE EJECUTA CUANDO DETECTA EL CAMBIO DE ESTADO/PROVINCIA = values.state_id */
  useEffect(() => {
//    if (!isLoading) {

      /*
      console.log("**************************************");
      console.log("values.country_id => ", values.country_id);
      console.log("values.state_id => ", values.state_id);
      console.log("**************************************");
      */

      if (values.state_id > 0) {

        CITY_listByCountryAndState(207, values.state_id);
        values.city_id = 0;
      } else {
        values.city_id = 0;
        setCities([
          {id: 0, name: "(Ciudades)"},
        ]);
      }
    //}
  }, [values.state_id]);

  /* SE EJECTURA UNA VEZ AL INGRESAR */
  useEffect(() => {

    setCountries([
      {id: 0, name: "(Todos)", currency: '', currency_symbol: ''},
    ]);

    setStates([
      {id: 0, name: "(Estados/Provincias)"},
    ]);

    setCities([
      {id: 0, name: "(Ciudades)"},
    ]);

    setMaterials([
      {id: 0, name: "(Todos)"},
    ]);

    setCommercialOperations([
      {id: 0, name: "(Todas)"},
    ]);


    /*
    console.log("***********************************************");
    console.log("search.country_id => ", search.country_id)
    console.log("search.state_id => ", search.state_id)
    console.log("search.city_id => ", search.city_id)
    console.log("***********************************************");
    console.log("values.country_id => ", values.country_id)
    console.log("values.state_id => ", values.state_id)
    console.log("values.city_id => ", values.city_id)
    console.log("***********************************************");
    */
    COUNTRY_List();
    
    if (search.country_id > 0) {
      STATE_listByCountry(search.country_id)
    }
    /*
    if (search.state_id > 0) {
      STATE_listByCountry(search.country_id)
    };
    */

    STATE_listByCountry(207)
    if (search.city_id > 0) {
      CITY_listByCountryAndState(search.country_id, search.state_id)
      values.city_id = search.city_id;
    };

    MATERIAL_List();
    COMMERCIALOPERATION_List();

    setIsLoading(false);
      
  }, []);

  /* SE EJECTURA PERMANENTEMENTE */
  /*  
  useEffect(() => {
  });
  */
  
  return (
    <>
      <Grid container spacing={gridSpacing} >
        <Grid item xs={12}>
          <form onSubmit={ handleSubmit}>
            <Card sx={{ width: 'auto', height: '100%', opacity: 0.9 }}>
              <CardContent sx={{ width: 'auto', height: '100%' }}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <TextField name="state_id" 
                      select={true}
                      label={label.state_id}
                      variant="outlined" 
                      sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                      fullWidth
                      onChange={handleChange}
                      value={values.state_id}
                      error={touched.state_id && errors.state_id !== undefined}
                      helperText={errors.state_id}
                    >
                      {
                        states.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField name="city_id" 
                      select={true}
                      label={label.city_id}
                      variant="outlined" 
                      sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                      fullWidth
                      onChange={handleChange}
                      value={values.city_id}
                      error={touched.city_id && errors.city_id !== undefined}
                      helperText={errors.city_id}
                    >
                      {
                        cities.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField name="material_id" 
                      select={true}
                      label={label.material_id}
                      variant="outlined" 
                      sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                      fullWidth
                      onChange={handleChange}
                      value={values.material_id}
                      error={touched.material_id && errors.material_id !== undefined}
                      helperText={errors.material_id}
                    >
                      {
                        materials.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField name="commercialoperation_id" 
                      select={true}
                      label={label.commercialoperation_id}
                      variant="outlined" 
                      sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                      fullWidth
                      onChange={handleChange}
                      value={values.commercialoperation_id}
                      error={touched.commercialoperation_id && errors.commercialoperation_id !== undefined}
                      helperText={errors.commercialoperation_id}
                    >
                      {
                        commercialOperations.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Grid item xs={12}>
                    <Typography>Capacidad</Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <TextField name="m3_min" 
                          type="number" 
                          label={label.m3_min}
                          variant="outlined" 
                          sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                          fullWidth
                          onChange={handleChange}
                          value={values.m3_min}
                          error={touched.m3_min && errors.m3_min !== undefined}
                          helperText={errors.m3_min}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField name="m3_max" 
                          type="number" 
                          label={label.m3_max}
                          variant="outlined" 
                          sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                          fullWidth
                          onChange={handleChange}
                          value={values.m3_max}
                          error={touched.m3_max && errors.m3_max !== undefined}
                          helperText={errors.m3_max}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Precio</Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <TextField name="price_min" 
                          type="number" 
                          label={label.price_min}
                          variant="outlined" 
                          sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                          fullWidth
                          onChange={handleChange}
                          value={values.price_min}
                          error={touched.price_min && errors.price_min !== undefined}
                          helperText={errors.price_min}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField name="price_max" 
                          type="number" 
                          label={label.price_max}
                          variant="outlined" 
                          sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                          fullWidth
                          onChange={handleChange}
                          value={values.price_max}
                          error={touched.price_max && errors.price_max !== undefined}
                          helperText={errors.price_max}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Antigüedad</Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <TextField name="years_min" 
                          type="number" 
                          label={label.years_min}
                          variant="outlined" 
                          sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                          fullWidth
                          onChange={handleChange}
                          value={values.years_min}
                          error={touched.years_min && errors.years_min !== undefined}
                          helperText={errors.years_min}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField name="years_max" 
                          type="number" 
                          label={label.years_max}
                          variant="outlined" 
                          sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                          fullWidth
                          onChange={handleChange}
                          value={values.years_max}
                          error={touched.years_max && errors.years_max !== undefined}
                          helperText={errors.years_max}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>

                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Box mt={2} display="flex" justifyContent="flex-start">
                      <Tooltip title="Limpiar Filtros">
                          <Button aria-label="limpiar" disabled={isSubmitting} onClick={ () => handleClean() } variant="outlined"><ClearOutlinedIcon fontSize='Large'/></Button>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Tooltip title="Buscar">
                          <Button aria-label="buscar" disabled={isSubmitting} type="submit" variant="contained"><SearchOutlinedIcon fontSize='Large'/></Button>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>

        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
          anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}>
        <Alert
          onClose={handleClose}
          severity={alerta.tipo}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerta.mensaje}
        </Alert>
      </Snackbar>

    </>
  );
}

/*







              <CardHeader title={ "Búsqueda Refinada" } />
              <Divider />



                    <TextField name="country_id" 
                      select={true}
                      label={label.country_id}
                      variant="outlined" 
                      sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                      fullWidth
                      onChange={handleChange}
                      value={values.country_id}
                      error={touched.country_id && errors.country_id !== undefined}
                      helperText={errors.country_id}
                    >
                      {
                        countries.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>


*/