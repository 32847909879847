import React, {useState, useEffect, useRef } from 'react';
import ReactPlayer from "react-player";
import Cookie from 'js-cookie';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';

import phone from '../assets/images/phone.svg';
import whatsapp from '../assets/images/whatsapp.svg';
import email from '../assets/images/email.svg';

import GetRegister from './Forms/Register/GetRegister';
import SendPhoneForm from './Forms/SendPhone';
import SendWhatsAppForm from './Forms/SendWhatsApp';
import SendMailForm from './Forms/SendMail';

import Paragraph from './Paragraph'

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

import Loader from './Loader/Loader';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Maps from "./Maps";
import "leaflet/dist/leaflet.css";

import { store } from '../store';
/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

//import logo from '../assets/images/buscatrasterologo_confondo.webp';

import GetMeta from '../components/GetMeta';

export default function PropertyDetail() {
  const videoRef = useRef();
  const [isMobile, setIsMobile] = useState(false)

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const propertyID = store.getState().currentSession.propertyID;
  const [isLinkVideo, setIsLinkVideo] = useState(false);

  const [property, setProperty] = React.useState({
    id: 1,
    title: "Prueba Uno",
    seo: "prueba-uno",
    country_id: 207,
    state_id: 5102,
    city_id: 32602,
    address: "Cucha Cucha 1520",
    latitude: "-32.42054050",
    longitude: "-63.22481370",
    description: "Probando",
    material_id: 1,
    total_mts3: 70,
    construction: "2024-04-19 00:00:00",
    commercialoperation_id: 1,
    currency: "EUR",
    price: "360",
    link_video: null,
    published: 1,
    outstanding: 1,
    user_id: 1,
    created_at: "2024-04-19T16:18:16.000000Z",
    updated_at: "2024-05-15T21:28:04.000000Z",
    country: {
        id: 207,
        name: "Spain",
        numeric_code: "724",
        currency: "EUR",
        currency_name: "Euro",
        currency_symbol: "€",
    },
    state: {
        id: 5102,
        name: "Barcelona",
        country_id: 207,
    },
    city: {
        id: 32602,
        name: "Badalona",
    },
    material: {
        id: 1,
        name: "Ladrillos",
        seo: "ladrillos",
    },
    commercialoperation: {
        id: 1,
        name: "Alquiler",
        seo: "alquiler",
    },
    propertytype: {
      id: 1,
      name: "Trastero",
      seo: "trastero",
    },
    user: {
        id: 1,
        first_name: "Gabriel",
        last_name: "Toscano",
        phone: "+54 9 11 6293-7778",
        email: "gabrieltoscano@gmail.com",
    },
    image_predetermined: [
        {
            id: 13,
            property_id: 1,
            name: "IMG-20240419-WA0002.jpg",
            ordering: 0,
            image: "fffff"
        }
    ]
  });
  const [parameters, setParameters] = useState(store.getState().parameters);

  const [images, setImages] = useState([]);
  const [selectPosition, setSelectPosition] = useState(null);
  const [url, setUrl] = useState("");

  const [openRegister, setOpenRegister] = useState(false);
  const [media, setMedia] = useState(0);
  const [openPhone, setOpenPhone] = useState(false);
  const [openWhatsApp, setOpenWhatsApp] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [user__CK, setUser__CK] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: ""
  })
  /*
    0: Phone
    1: Whatsapp
    2: Email
  */

  const handleRegisterClick = (media) => {

    setMedia(media);
    if ( !(Cookie.get("__CK_registered") === undefined) && Cookie.get("__CK_registered") ) {
      // HAY COOKIE CREADA Y ES VERDADERA

      switch (media) {
        case 0: // Phone
          setOpenPhone(true);
          break;

        case 1: //Whatsapp
          setOpenWhatsApp(true);
          break;

        case 2: // Email
          setOpenMail(true);
          break;
        
        default:
      }
    } else {
      setOpenRegister(true);
    }
  
  };


  const handleRegisterOnClose = (validated, user) => {
    setOpenRegister(!validated) 

    setUser__CK({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone
    })    

    /*
    console.log("*****************************************");
    console.log("validated => ", validated);
    console.log("*****************************************");
    */
  }

  const handleOnCloseSendMailForm = () => {
    setOpenMail(false);
  }

  const handleOnCloseSendwhatsAppForm = () => {
    setOpenWhatsApp(false);
  }
  const handleOnCloseSendPhoneForm = () => {
    setOpenPhone(false);
  }  


  const PROPERTY_GetID = async() => {
      
    try {
      const response = await SiteService.PROPERTY_GetID(propertyID);

      setProperty(response.datos.data);
/*
      console.log("=========================================================");
      console.log("Horario  => ", new Date());
      console.log("propertyID => ", propertyID);
      console.log("response => ", response);
      console.log("selectPosition => ", selectPosition);
      console.log("=========================================================");
*/ 

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }

  };
  
  const IMAGE_FindByPropertyID = async() => {
    let imagesRead = [];
    let imageDefault = parameters.parameters.imageDefault;
    
    try {
      const response = await SiteService.IMAGE_FindByPropertyID(propertyID);

      if (response.datos.data.length > 0) {
        response.datos.data.forEach(i => {
          imagesRead.push(
            {
              original : parameters.parameters.httpConfig.backEndURL + 'storage/' + i.path + i.name,
              thumbnail : parameters.parameters.httpConfig.backEndURL + 'storage/' + i.path + i.name,
            },
          )
        });
        setImages(imagesRead);
      } else {
        setImages([{
          original: imageDefault,
          thumbnail:  imageDefault
        }]);

      }
/*        
      console.log("=========================================================");
      console.log("Horario    => ", new Date());
      console.log("propertyID => ", propertyID);
      console.log("response   => ", response);
      console.log("images     => ", images);
      console.log("=========================================================");
*/  
    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }

  }
  
  const isEmpty = (value) => {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }
  
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }


  /* SE EJECTURA UNA VEZ AL INGRESAR */
  useEffect(() => {
    window.scrollTo(0, 0);
    handleResize();

    PROPERTY_GetID();
    IMAGE_FindByPropertyID();    
    setIsLoading(false);
/*
    console.log("***************************************************");
    console.log("Horario  => ", new Date());
    console.log("property => ", property);
    console.log("***************************************************");
*/
    setUrl(window.location.href);

    if ( !(Cookie.get("__CK_registered") === undefined) && Cookie.get("__CK_registered") ) {
      // HAY COOKIE CREADA Y ES VERDADERA
      if (Cookie.get("__CK_user") === "undefined") {
        setUser__CK({
          email: "",
          first_name: "",
          last_name: "",
          phone: ""
        });
      } else {
        let tmpUser = JSON.parse(Cookie.get("__CK_user"));
        setUser__CK({
          email: tmpUser.email,
          first_name: tmpUser.first_name,
          last_name: tmpUser.last_name,
          phone: tmpUser.phone
        })
      }

    } else {
      setUser__CK({
        email: "",
        first_name: "",
        last_name: "",
        phone: ""
      });
    }


  }, []);

  useEffect( () => {
    setSelectPosition({
      lat: property.latitude,
      lon: property.longitude
    });
/*
      console.log("***************************************************");
      console.log("Horario  => ", new Date());
      console.log("selectPosition => ", selectPosition);
      console.log("***************************************************");
*/

    if ( isEmpty(property.link_video) ) {
      setIsLinkVideo(false);
    } else {
      setIsLinkVideo(true);
    }

  }, [property])


  if (isLoading) return <Loader />;
  
  if (!isLoading) return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}
          sx={{ mt: theme.spacing(3), ml: theme.spacing(3), mr: theme.spacing(3) }}
        >
          <Typography variant='h2' color='primary'> 
            { property.title }
          </Typography > 
          <Typography variant='h4' color='primary'> 
            <LocationOnOutlinedIcon fontSize='Large'/>
            { property.address },{ property.city.name }, { property.state.name }, { property.country.name }  
          </Typography > 
        </Grid>
        <Grid item xs={12}
          sx={{ mt: theme.spacing(3), ml: theme.spacing((isMobile? 0: 3)), mr: theme.spacing((isMobile? 0: 3)) }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={8}>
              <Card 
              elevation={0} 
              sx={{ 
                width:"100%", 
                maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Fotos
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>

                      <ImageGallery
                        items={images}
                        showBullets={true}
                        showIndex={true}
                        showThumbnails={true}
                        infinite={true}
                        lazyLoad={true}
                        showPlayButton={true}
                        showNav={true}
                        showFullscreenButton={true}
                        thumbnailPosition={ (isMobile? "bottom": "left")}
                        disableThumbnailScroll={true}
                      />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>

                  <Card 
                    elevation={0} 
                    sx={{ 
                      width:"100%", 
                      maxWidth: "100%",
                  }}>
                    <CardHeader
                      title={
                        <Typography variant="h4">
                          { property.commercialoperation.name }
                        </Typography>
                      }
                    />
                    <Divider variant='middle'/>
                    <CardContent>
                      <Typography variant="h4">
                        { property.currency } { property.price }
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card 
                    elevation={0} 
                    sx={{ 
                      width:"100%", 
                      maxWidth: "100%",
                  }}>
                    <CardHeader
                      title={
                        <Typography variant="h4">
                          Características
                        </Typography>
                      }
                    />
                    <Divider variant='middle'/>
                    <CardContent>
                      <Grid container spacing={gridSpacing}>
                        <Grid item xs={4}>
                          <WarehouseOutlinedIcon fontSize='large'/>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="h4">
                            { property.material.name }
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <SquareFootOutlinedIcon fontSize='large'/>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="h4">
                            { property.total_mts3 } mts<sup>2</sup>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card 
                    elevation={0} 
                    sx={{ 
                      width:"100%", 
                      maxWidth: "100%",
                  }}>
                  <CardHeader
                    title={
                      <Typography variant="h4">
                        ¡Comunícate!
                      </Typography>
                    }
                  />
                  <Divider variant='middle'/>
                    <CardContent>
                      <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                          <Box mt={2}  display="flex" justifyContent="flex-end">
                            <ButtonGroup size="Large" aria-label="button group" variant="text" color="primary">
                              <Button aria-label="Phone"    color="terciary" onClick={ () => handleRegisterClick(0) } ><img src={phone} height="75" width="75"/></Button>
                              <Button aria-label="WhatsApp" color="terciary" onClick={ () => handleRegisterClick(1) } ><img src={whatsapp} height="75" width="75"/></Button>
                              <Button aria-label="Email"    color="terciary" onClick={ () => handleRegisterClick(2) } ><img src={email} height="75" width="75"/> </Button>
                            </ButtonGroup>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>


              </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}
          sx={{ ml: theme.spacing(3), mr: theme.spacing(3) }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

              <Card 
                elevation={0} 
                sx={{ 
                  width:"100%", 
                  maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Descripción
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={12} xs={12}>

                      <Paragraph
                        text={ (typeof property.description === "string" && property.description.length > 0) ? property.description : "" }
                      />

                    </Grid> 
                  </Grid>
                </CardContent>
              </Card>


            </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}
          sx={{ ml: theme.spacing(3), mr: theme.spacing(3) }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

              <Card 
                elevation={0} 
                sx={{ 
                  width:"100%", 
                  maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Ubicación
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={12} xs={12}>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: "row",
                          height: "60vh",
                          width: "100%",
                        }}
                      >
                        <div style={{ height: "60vh", width: "100%" }} >
                          <Maps selectPosition={selectPosition}/>
                        </div>
                      </div>

                    </Grid> 
                  </Grid>
                </CardContent>
              </Card>

            </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}
          sx={{ mb: theme.spacing(3), ml: theme.spacing(3), mr: theme.spacing(3), display: ( isLinkVideo ? 'block': 'none' ) }}

        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

              <Card 
                elevation={0} 
                sx={{ 
                  width:"100%", 
                  maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Video 360º
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={12} xs={12}>

                    <div ref={videoRef}>
                      <ReactPlayer
                        width="100%"
                        height="600px"
                        controls={true}
                        url={ property.link_video }
                        config={{ file: { attributes: { controlsList: "nodownload" } } }}
                      />
                    </div>


                    </Grid> 
                  </Grid>
                </CardContent>
              </Card>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GetRegister 
        open={ openRegister } 
        onClose={ handleRegisterOnClose }
      />
      <Dialog
        open={ openPhone }
        onClose={ () => setOpenPhone(false) }
      >
        
        <Box sx={{ m: theme.spacing(4) }}>
          <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{ user__CK.first_name } </Typography>          
                <Typography variant="subtitle1" gutterBottom color={"grey"}>¡Estás cada vez más cerca de conseguir tu Trastero!</Typography>          
                <Divider/>
                <SendPhoneForm data={{
                    email: user__CK.email,
                    first_name: user__CK.first_name,
                    last_name: user__CK.last_name,
                    phone: user__CK.phone,
                    subject: "[ID: " + property.id + " ] " + property.title + " - INTERÉS",
                    content: "¡Hola! Quiero que se comuniquen conmigo por este Trastero que vi en Buscatrastero.\n [ID: " + property.id + " ] " + property.title,
                    receiver: property.user.email,
                    user_first_name: property.user.first_name,
                    user_phone: property.user.phone
                  }} 
                  onClose={ handleOnCloseSendPhoneForm }
                />
                <Divider/>
                <Typography variant="subtitle1" gutterBottom color={"grey"}>Te mostramos el teléfono y enviamos un mensaje para que te contacte el anunciante.</Typography>          
              </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Dialog
        open={ openWhatsApp }
        onClose={ () => setOpenWhatsApp(false) }
      >
        
        <Box sx={{ m: theme.spacing(4) }}>
          <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{ user__CK.first_name } </Typography>          
                <Typography variant="subtitle1" gutterBottom color={"grey"}>¡Estás cada vez más cerca de conseguir tu Trastero!</Typography>          
                <Divider/>
                <SendWhatsAppForm data={{
                    email: user__CK.email,
                    first_name: user__CK.first_name,
                    last_name: user__CK.last_name,
                    phone: user__CK.phone,
                    subject: "[ID: " + property.id + " ] " + property.title + " - INTERÉS",
                    content: "¡Hola! Me estoy comunicando por WhatsApp por este Trastero que vi en Buscatrastero.\n [ID: " + property.id + " ] " + property.title,
                    receiver: property.user.email,
                    user_first_name: property.user.first_name,
                    user_phone: property.user.phone
                  }} 
                  onClose={ handleOnCloseSendwhatsAppForm }
                />
                <Divider/>
                <Typography variant="subtitle1" gutterBottom color={"grey"}>Te mostramos el teléfono y enviamos un mensaje para que te contacte el anunciante.</Typography>          
              </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Dialog
        open={ openMail }
        onClose={ () => setOpenMail(false) }
      >
        
        <Box sx={{ m: theme.spacing(4) }}>
          <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{ user__CK.first_name } </Typography>          
                <Typography variant="subtitle1" gutterBottom color={"grey"}>¡Estás cada vez más cerca de conseguir tu Trastero!</Typography>          
                <Divider/>
                <Typography align='center' variant="h6"display="block" gutterBottom color={"black"}>Tomá contacto con { property.user.first_name }</Typography>          
                <SendMailForm data={{
                    email: user__CK.email,
                    first_name: user__CK.first_name,
                    last_name: user__CK.last_name,
                    phone: user__CK.phone,
                    subject: "[ID: " + property.id + " ] " + property.title + " - INTERÉS",
                    content: "¡Hola! Quiero que se comuniquen conmigo por este Trastero que vi en Buscatrastero.\n [ID: " + property.id + " ] " + property.title,
                    receiver: property.user.email
                  }} 
                  onClose={ handleOnCloseSendMailForm }
                />
              </Grid>
          </Grid>
        </Box>
      </Dialog>

      <GetMeta page={3} data={ property } url={ url } />
    </>
  );
}
