import callApi from "./CallApi";

const COUNTRY_List = async (legend, data = {}) => {
  if (legend === "") {
    const res = await callApi("GET", `/v1/site/countryList`, data);
    return res;
  } else {    
    const res = await callApi("GET", `/v1/site/countryList/${legend}`, data);
    return res;
  }
};


const STATE_ListByCountry = async (country_id, data = {}) => {
  const res = await callApi("GET", `/v1/site/stateListByCountry/${country_id}`, data);
  return res;
};

const CITY_ListByCountryAndState = async (country_id, state_id, data = {}) => {
  const res = await callApi("GET", `/v1/site/cityListByCountryAndState/${country_id}/${state_id}`, data);
  return res;
};

const MATERIAL_List = async (data = {}) => {
  const res = await callApi("GET", `/v1/site/materialList`, data);
  return res;
};

const COMMERCIALOPERATION_List = async (legend, data = {}) => {
  if (legend === "") {
    const res = await callApi("GET", `/v1/site/commercialOperationList`, data);
    return res;
  } else {    
    const res = await callApi("GET", `/v1/site/commercialOperationList/${legend}`, data);
    return res;
  }
};

const PROPERTY_BySearchCriteria = async (data = {}) => {
  const res = await callApi("POST", `/v1/site/propertyBySearchCriteria`, data);
  return res;
};

const PROPERTY_ByOutstanding = async (data = {}) => {
  const res = await callApi("GET", `/v1/site/propertyByOutstanding`, data);
  return res;
};

const PROPERTY_GetID = async (id, data = {}) => {
  const res = await callApi("GET", `/v1/site/propertyGetID/${id}`, data);
  return res;
};

const IMAGE_FindByPropertyID = async (id, data = {}) => {
  const res = await callApi("GET", `/v1/site/imageFindByPropertyID/${id}`, data);
  return res;
};

const ADVERTINSINGSLIDER_CompleteList = async (data = {}) => {
  const res = await callApi("GET", `/v1/site/advertisingSliders`, data);
  return res;
};

const ADVERTINSINGCAROUSEL_CompleteListByPage = async (page, data = {}) => {
  const res = await callApi("GET", `/v1/site/advertisingCarouselsByPage/${page}`, data);
  return res;
};

const FAQ_List = async (data = {}) => {
  const res = await callApi("GET", `/v1/site/faqList`, data);
  return res;
};

const FIXEDCONTENT_fixedContentGetByID = async (id, data = {}) => {
  const res = await callApi("GET", `/v1/site/fixedContentGetByID/${id}`, data);
  return res;
};

const METATAG_metaTagGetByID = async (id, data = {}) => {
  const res = await callApi("GET", `/v1/site/metaTagGetByID/${id}`, data);
  return res;
};

const PROPERTY_countByCountryID = async (country_id, data = {}) => {
  const res = await callApi("GET", `/v1/site/propertyCountByCountryID/${country_id}`, data);
  return res;
};

const PARAMETER_Get = async (data = {}) => {
  const res = await callApi("GET", `/v1/site/getParameters`, data);
  return res;
};

const SENDMAIL = async (data = {}) => {
  const res = await callApi("POST", `/v1/site/sendMail`, data);
  return res;
};

const OPERATIONLOG_Register = async (data = {}) => {
  const res = await callApi("POST", `/v1/site/operationLogRegister`, data);
  return res;
};

const SiteService = {
  COUNTRY_List,
  STATE_ListByCountry,
  CITY_ListByCountryAndState,
  MATERIAL_List,
  COMMERCIALOPERATION_List,
  PROPERTY_BySearchCriteria,
  PROPERTY_ByOutstanding,
  PROPERTY_GetID,
  IMAGE_FindByPropertyID,
  ADVERTINSINGSLIDER_CompleteList,
  ADVERTINSINGCAROUSEL_CompleteListByPage,
  FAQ_List,
  FIXEDCONTENT_fixedContentGetByID,
  METATAG_metaTagGetByID,
  PROPERTY_countByCountryID,
  PARAMETER_Get,
  SENDMAIL,
  OPERATIONLOG_Register
};

export default SiteService;
