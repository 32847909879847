import React from 'react';

import GetPolitics from '../components/GetPolitics';

const Politics = () => {
  return (
    <>
      <GetPolitics />
    </>
  )
}

export default Politics