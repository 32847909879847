import React, { useState, useEffect } from 'react'
import {  
  styled, 
  useTheme,
  Drawer,
  Divider,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'

import { store } from '../store';

// rutas
import { Link } from 'react-router-dom';
// icons

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';


const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

//rotas
const itemList = [
  {
    text: "Home",
    icon: <HomeOutlinedIcon />,
    to: "/"
  },
  {
    text: "Propiedades",
    icon: <WarehouseOutlinedIcon />,
    to: "/property"
  },
  {
    text: "Nosotros",
    icon: <InfoOutlinedIcon />,
    to: "/aboutus"
  },
  {
    text: "Preguntas",
    icon: <QuizOutlinedIcon />,
    to: "/faq"
  },
  {
    text: "Contáctenos",
    icon: <EmailOutlinedIcon />,
    to: "/contact"
  }
];


const DrawerItem = () => {
  const [parametros, setParametros] = useState(store.getState().parameters);
  const [adminURL, setAdminURL] = useState("");
  const [registerURL, setRegisterURL] = useState("");

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {

    //        setAdminURL(parametros.httpConfig.adminURL);
    /*
            console.log("****************************************");
            console.log(parametros);
            console.log(parametros.parameters);
            console.log(parametros.parameters.httpConfig);
            console.log(parametros.parameters.httpConfig.adminURL);
            console.log("****************************************");
    */        
            setAdminURL(parametros.parameters.httpConfig.adminURL);
            setRegisterURL(parametros.parameters.httpConfig.registerURL);
    
  }, [parametros])
    
  return (
    <>
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{ ...(open && { display: 'none' }), ml: '5px', mr: '5px' }}
      >
        <MenuOutlinedIcon fontSize='large' />
      </IconButton>

      <Drawer
      sx={{
          flexGrow: 1,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
          width: drawerWidth,
          },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightOutlinedIcon /> : <ChevronLeftOutlinedIcon /> }
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemList.map( ( item ) => {
            const { text } = item;
            return(
              <ListItem 
              key={text} 
              component={Link} 
              to={item.to}
              sx={{
                color: '#414141',
                "&:hover": {
                  backgroundColor: '#e9e5e5',
                  color: '#1c2859',
                }
              }}
              >
                <ListItemIcon
                sx={{
                  "&:hover":{
                    backgroundColor: 'transparent',
                    color: '#1c2859',
                  }
                }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            )
          })}
          <Divider />
          <ListItem 
            sx={{
              color: '#414141',
              "&:hover": {
                backgroundColor: '#e9e5e5',
                color: '#1c2859',
              }
            }}
            onClick={ () => { window.open(registerURL, "_blank") } }
          >
            <ListItemIcon
            sx={{
              "&:hover":{
                backgroundColor: 'transparent',
                color: '#1c2859',
              }
            }}
            >
              <NewspaperOutlinedIcon/>
            </ListItemIcon>
            <ListItemText primary=" Publicá Gratis" />
          </ListItem>
          <Divider />
          <ListItem 
            sx={{
              color: '#414141',
              "&:hover": {
                backgroundColor: '#e9e5e5',
                color: '#1c2859',
              }
            }}
            onClick={ () => { window.open(adminURL, "_blank") } }
          >
            <ListItemIcon
            sx={{
              "&:hover":{
                backgroundColor: 'transparent',
                color: '#1c2859',
              }
            }}
            >
              <AdminPanelSettingsOutlinedIcon/>
            </ListItemIcon>
            <ListItemText primary=" Login" />
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}

export default DrawerItem;