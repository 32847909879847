import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

// material-ui
import { 
  Alert,
  Card, 
  CardHeader, 
  CardContent, 
  Divider, 
  Grid,
  Stack,
  Typography 
} from '@mui/material';

import Title from './Title';
import Paragraph from './Paragraph';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

import Loader from './Loader/Loader';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

// ==============================|| SAMPLE PAGE ||============================== //

export default function GetAboutUs() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [alerta, setAlerta] = React.useState({
    tipo: 'error',
    mensaje: ''
  });
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  async function fetchData() {
    try {
      const response = await SiteService.FIXEDCONTENT_fixedContentGetByID(2);
      setData(response.datos.data);
    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      setError(errorMessage);
      console.log(errorMessage);
    }
    setIsLoading(false);
  }
  
  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <Loader />;
  if (error) return error;

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item  xs={12} sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h1" className="card-header" align='center'>
                  { data[0].title }
                </Typography>
              }
            />
            <Typography variant="subtitle2" align='center'>
                { data[0].subtitle }
            </Typography>
            <Divider />
            <CardContent>
              <Stack>
                { parse(data[0].content) }
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
