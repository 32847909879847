import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';

//  third party
import * as Yup from 'yup';
import { Formik } from 'formik';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../../../services/SiteService';

// ==============================|| FIREBASE LOGIN ||============================== //

const SendWhatsAppForm = ({ data, onClose }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [alerta, setAlerta] = useState({
    tipo: 'error',
    mensaje: ''
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSendWhatsAppForm = () => {

    onClose(true);
  };

  return (
    <>
      <Formik
        initialValues={{
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          subject: data.subject,
          content: data.content,
          receiver: data.receiver,
          user_first_name: data.user_first_name,
          user_phone: data.user_phone
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Debe ser un correo electrónico válido').max(255).required('Correo electronico es requerido'),
          first_name: Yup.string().max(255).required('Se requiere un nombre'),
          last_name: Yup.string().max(255).required('Se requiere un apellido'),
          phone:  Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Debe ser un Teléfono Celular válido').required('Se requiere el Teléfono Celular'),
        })}
        onSubmit={ (values) => {
          try {
            SiteService.SENDMAIL({
                type: "property",
                email: values.email,
                name: values.first_name + " " + values.last_name,
                phone: values.phone,
                subject: values.subject,
                content: values.content,
                receiver: values.receiver
            });
    
            SiteService.OPERATIONLOG_Register({
                type: "property",
                action: "email",
                object: JSON.stringify({
                    email: values.email,
                    name: values.first_name + " " + values.last_name,
                    phone: values.phone,
                    subject: values.subject,
                    content: values.content,
                    receiver: values.receiver,
                    user_first_name: values.user_first_name,
                    user_phone: values.user_phone
          
                })
            });

            /*
              https://api.whatsapp.com/send/?phone=5491171302395
              &text=%C2%A1Hola%21+Quiero+que+se+comuniquen+conmigo+por+esta+propiedad+en++Venta++que+vi+en++zonaprop.+https%3A%2F%2Fwww.zonaprop.com.ar%2Fpropiedades%2Fclasificado%2Fveclapin-departamento-venta-barracas-3-ambientes-53542108.html&type=phone_number&app_absent=0
            */
              var anchor = document.createElement('a');
              var url = "https://api.whatsapp.com/send/?phone=";
              
              var txtPhone = values.user_phone;
              var txtDescription = values.content;
          
              anchor.href = url + txtPhone + "&text=" + txtDescription;
              anchor.target="_blank";
              anchor.click();

            handleCloseSendWhatsAppForm();

          } catch (err) {
            setAlerta({
              tipo: "error",
              mensaje: "La cuenta de correo ya está registrada en la plataforma."
            })
            setOpen(true);
          }

        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Typography align='center' variant="h6"display="block" gutterBottom color={"black"}>{ values.user_first_name }</Typography>          
            <Typography align='center' variant="h6"display="block" gutterBottom color={"#6d429b"}>{ values.user_phone }</Typography>          

            <Box mt={2}>
              <Button color="primary" disabled={isSubmitting } fullWidth size="large" type="submit" variant="contained">
                WHATSAPP
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alerta.tipo}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerta.mensaje}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SendWhatsAppForm;
