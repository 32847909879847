import { Routes, Route } from 'react-router-dom';

//pages
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import Contact from '../pages/Contact';
import Property from '../pages/Property';
import PropertyDetail from '../pages/PropertyDetail';
import PropertyEmail from '../pages/PropertyEmail';
import Faq from '../pages/Faq';
import Politics from '../pages/Politics';
import Cookies from '../pages/Cookies';

import NotFound from '../pages/NotFound';

import Sitemap from '../services/Sitemap';

const MainRoutes = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const today = `${year}-${month}-${day}`;
  return (
    <Routes>
      <Route path='/' element={<Home />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/aboutus' element={<AboutUs />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/contact' element={<Contact />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/faq' element={<Faq />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/politics' element={<Politics />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/cookies' element={<Cookies />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/property' element={<Property />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/property/detail' element={<PropertyDetail />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path='/property/email' element={<PropertyEmail />} sitemapIndex='true' changefreq='weekly' priority='1' lastmod={ today } />
      <Route path="*" element={<NotFound />} />
      <Route path="/sitemap.xml" element={<Sitemap />}></Route>
    </Routes>
  )
}

export default MainRoutes;