import React, { useEffect, useState } from 'react'
import { 
    Box, 
    Grid,
    styled, 
    Typography 
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from '../config';

//img
//import headerImg from '../assets/pexels-binyamin-mellish-186078.png'
//import headerImg from '../assets/deposito.webp';
import headerImg from '../assets/images/home01.webp';
import Search from './Forms/Search';

const Header = () => {
    const theme = useTheme();
    const [isMobile, setIsMobile] = useState(false)
  
    const CustomBox = styled(Box) (({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        // tamaños
        gap: theme.spacing(2),
        paddingTop: theme.spacing(3),

        // Imagen de Fondo
        backgroundImage: `url(${headerImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        }
    }));

    const BoxText = styled(Box) (({ theme }) => ({
        flex: '1',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
        [theme.breakpoints.down('sm')]: {
            flex: '2',
            textAlign: 'center',
        }
    }));


    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })
    // create an event listener
    
    useEffect(() => {
        handleResize();
    }, [])

    return  (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <CustomBox 
                    component='header'
                >
                    <Search />
                </CustomBox>
            </Grid>
        </Grid>
    )
}

export default Header


/*

    const BoxText = styled(Box) (({ theme }) => ({
        flex: '1',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing((isMobile? 50: 30)),
        paddingRight: theme.spacing((isMobile? 50: 30)),
        [theme.breakpoints.down('sm')]: {
            flex: '2',
            textAlign: 'center',
            paddingLeft: theme.spacing(30),
            paddingRight: theme.spacing(30),
            display: 'block'
        }
    }));

    
    const BoxSearch = styled(Box) (({ theme }) => ({
        flex: '1',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(35),
        paddingRight: theme.spacing(35),
        [theme.breakpoints.down('md')]: {
            flex: '2',
            textAlign: 'center',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            display: 'block'
        },
        opacity: 0.4, 
        backgroundColor: '#6d429b'
    }));

*/