import * as React from 'react';

import {
  Paper,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';

export default function TooogleButtons({ options = [{id: 0, name: '(Vacio)'}, {id: 1, name: 'Uno'}], onChange }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [listOptions, setListOptions] = React.useState([]);

  /*
  const [listOptions, setListOptions] = React.useState([
    {id: 0, name: 'Create a merge commit'}, 
    {id: 1, name: 'Squash and merge'}, 
    {id: 2, name: 'Rebase and merge'}
  ]);
*/  
  const handleClick = () => {
    console.info(`handleClick ${listOptions[selectedIndex].id} - ${listOptions[selectedIndex].name}`);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);

//    console.info(`handleMenuItemClick ${listOptions[index].id} - ${listOptions[index].name}`);
//    console.info(`handleMenuItemClick ${listOptions[selectedIndex].id} - ${listOptions[selectedIndex].name}`);
    onChange(event, listOptions[index].id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, index) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    /*
    console.log("***********************************************");
    console.log("Horario: ", new Date());
    console.log("options ==>> ", options);
    console.log("***********************************************");
    */
    setListOptions(options);
  }, [options])

  return (
    <React.Fragment>
      <Paper
        color="terciary"
      >
        <ToggleButtonGroup
            variant="contained"
            color="terciary"
            backgroundColor="primary"
            exclusive
          >
            {listOptions.map((option, index) => (
              <ToggleButton
                key={option.id}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                { option.name }
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
      </Paper>
    </React.Fragment>
  );
}
