import callApi from "./CallApi";

/*
  POST            api/auth/login generated::3L9SL7PVcDMw7gvz  ÔÇª
  POST            api/auth/logout generated::llQo3EDQ676hsDwS ÔÇª
  POST            api/auth/refresh generated::7yUBAK2H3JozBvbfÔÇª
  POST            api/auth/register generated::RxFzxD3dhzUZDnrÔÇª
  GET|HEAD        api/auth/user-profile generated::MwRZjpCkzvPÔÇª

*/

const login = async (data = {}) => {
    const res = await callApi("POST", "/auth/login", data);
    return res;
};

const logout = async (data = {}) => {
  const res = await callApi("POST", "/auth/logout", data);
  return res;
};

const register = async (data = {}) => {
  const res = await callApi("POST", "/auth/register", data);
  return res;
};

const verificationCode = async (data = {}) => {
  const res = await callApi("POST", "/auth/verificationcode", data);
  return res;
};

const resetPassword = async (data = {}) => {
  const res = await callApi("POST", "/auth/resetpassword", data);
  return res;
};


/*
const refresh = () => {
  return http.post("/api/auth/refresh");
};

const userProfile = () => {
  return http.get("api/auth/user-profile");
};
*/
const AuthService = {
  login,
  logout,
  register,
  verificationCode,
  resetPassword
//  refresh,
//  userProfile,
};

export default AuthService;
