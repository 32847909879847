import React, {useState, useEffect} from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    Button,
    List,
    ListItem,
    Tooltip,
    styled,
    ListItemButton,
    ListItemText,
    Slide,
    useScrollTrigger
} from '@mui/material';

import { store } from '../store';

// menu
import DrawerItem from './DrawerItem';
// rotas
import { Link } from 'react-router-dom';

import logo from '../assets/images/buscatrasterologo.webp';

// personalizacao
const StyledToolbar = styled(Toolbar) ({
    display: 'flex',
    justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")] : {
        display: "flex",
    },
}));

//rotas
const itemList = [
    {
      text: "Home",
      to: "/" 
    },
    {
        text: "Propiedades",
        to: "/property"
    },
    {
      text: "Nosotros",
      to: "/aboutus"
    },
    {
        text: "Preguntas",
        to: "/faq"
    },
    {
        text: "Contáctenos",
        to: "/contact"
    }
];


const HideOnScroll = ({ children }) => {
    const trigger = useScrollTrigger();
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };

const Navbar = () => {
    const [parametros, setParametros] = useState(store.getState().parameters);
    const [adminURL, setAdminURL] = useState("");
    const [registerURL, setRegisterURL] = useState("");

    useEffect(() => {

//        setAdminURL(parametros.httpConfig.adminURL);
/*
        console.log("****************************************");
        console.log(parametros);
        console.log(parametros.parameters);
        console.log(parametros.parameters.httpConfig);
        console.log(parametros.parameters.httpConfig.adminURL);
        console.log("****************************************");
*/        
        setAdminURL(parametros.parameters.httpConfig.adminURL);
        setRegisterURL(parametros.parameters.httpConfig.registerURL);

    }, [parametros])

    return (
        <HideOnScroll>

            <AppBar 
            component="nav" 
            position="sticky"
            sx={{ 
                backgroundColor: 'white', 
            }}
            elevation={0}
            >
                <StyledToolbar>
                    <Box>
                        <img src={logo} alt="Logo" width="100px" height="100px"/>
                    </Box>
                    <Box>
                        <Tooltip 
                            title="Registrate en Nuestro Portal y publicá gratis tu trastero."
                        >
                            <a 
                                href={ registerURL }
                                rel='noreferrer'
                                target='_blank'
                            >
                                <Button variant="contained">PUBLICA<br/>GRATIS</Button>
                            </a>
                        </Tooltip>
                    </Box>
                    <Box sx={{display: { xs: 'block', sm: 'none' } }}>
                        <DrawerItem /> 
                    </Box>
                    <ListMenu>
                        {itemList.map( ( item ) => {
                            const { text } = item;
                            return(
                                <ListItem key={text}>
                                    <ListItemButton component={Link} to={item.to}
                                    sx={{
                                        color: '#primary',
                                        "&:hover": {
                                            backgroundColor: 'transparent',
                                            color: '#1e2a5a',
                                        }
                                    }}
                                    >
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                        <ListItem key={"login"}>
                            <ListItemButton
                                sx={{
                                    color: '#primary',
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        color: '#1e2a5a',
                                    }
                                }}
                                onClick={ () => { window.open(adminURL, "_blank") } }
                            >
                                <ListItemText primary={"Login"} />
                            </ListItemButton>
                        </ListItem>
                    </ListMenu>
                </StyledToolbar>
            </AppBar>
        </HideOnScroll>
    )
}

export default Navbar;
