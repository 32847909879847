import React, { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Snackbar,
  Alert
} from '@mui/material';

//  third party
import * as Yup from 'yup';
import { Formik } from 'formik';

import { store } from '../../../../store';

/* LLAMADAS DE SERVICIOS API */
import AuthService from '../../../../services/AuthService';

// ==============================|| FIREBASE LOGIN ||============================== //

//const PhoneForm = ({ ...rest, onChange }) => {
const PhoneForm = ({ onChange }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [alerta, setAlerta] = useState({
    tipo: 'error',
    mensaje: ''
  });
  const [checked, setChecked] = useState(false);

  const [parametros, setParametros] = useState(store.getState().parameters);
  const [adminURL, setAdminURL] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (email, first_name, last_name, phone) => {
    onChange(true, {
      email: email,
      first_name: first_name,
      last_name: last_name,
      phone: phone
    });
  };

  useEffect(() => {
    setAdminURL(parametros.parameters.httpConfig.adminURL);
  }, [parametros])

  useEffect(() => {
    setAdminURL(parametros.parameters.httpConfig.adminURL);
  }, [parametros])

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          first_name: '',
          last_name: '',
          phone: '',
          type: 'user',
          password: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MjM4OTQ4MzksImV4cCI6MTcyMzg5ODQzOSwibmJmIjoxNzIzODk0ODM5LCJqdGkiOiJHQ29vaE5RWUhkNWkwNkQ4Ii'
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Debe ser un correo electrónico válido').max(255).required('Correo electronico es requerido'),
          first_name: Yup.string().max(255).required('Se requiere un nombre'),
          last_name: Yup.string().max(255).required('Se requiere un apellido'),
          phone:  Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Debe ser un Teléfono Celular válido').required('Se requiere el Teléfono Celular'),
        })}
        onSubmit={ async (values) => {
          setOpen(false);
          let res = await AuthService.register(values);
          if(res.status) {
            
            handleOnChange(values.email, values.first_name, values.last_name, values.phone);

          } else {
            setAlerta({
              tipo: "error",
              mensaje: "La cuenta de correo ya está registrada en la plataforma."
            })
            setOpen(true);
            handleOnChange(values.email, values.first_name, values.last_name, values.phone);
          }

        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
//          <form noValidate onSubmit={handleSubmit} {...rest}>
          <form noValidate onSubmit={handleSubmit}>

            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Dirección correo"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.first_name && errors.first_name)}
              fullWidth
              helperText={touched.first_name && errors.first_name}
              label="Nombre"
              margin="normal"
              name="first_name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.first_name}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.last_name && errors.last_name)}
              fullWidth
              helperText={touched.last_name && errors.last_name}
              label="Apellido"
              margin="normal"
              name="last_name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.last_name}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              helperText={touched.phone && errors.phone}
              label="Teléfono"
              margin="normal"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.phone}
              variant="outlined"
            />

            <Box my={0}>
              <FormControlLabel
                control={
                  <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} name="checked" color="primary" />
                }
                label={
                  <>
                    <a 
                        href={ adminURL + "termandcondition" }
                        rel='noreferrer'
                        target='_blank'
                    >
                      <Typography variant="subtitle2" color="primary" sx={{ textDecoration: 'none' }}>He leído Términos y Condiciones</Typography>
                    </a>                  
                  </>
                }
              />
            </Box>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box mt={2}>
              <Button color="primary" disabled={isSubmitting || !checked } fullWidth size="large" type="submit" variant="contained">
                Enviar
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alerta.tipo}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerta.mensaje}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PhoneForm;
