import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  styled,
  Toolbar,
  Typography
} from '@mui/material';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';
import Loader from '../components/Loader/Loader';

import PropertyCard from './PropertyCard';

import logo from '../assets/images/destacados.webp';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

// personalizacao
const StyledToolbar = styled(Toolbar) ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white', 

});


export default function Outstanding() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);

  const PROPERTY_ByOutstanding = async () => {
    
    try {
      const response = await SiteService.PROPERTY_ByOutstanding();
/*
      console.log("***********************************************");
      console.log("Horario: ", new Date());
      console.log("response.datos.data ==>> ", response.datos.data);
      console.log("***********************************************");
*/
      setProperties(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }

  }

  useEffect(() => {
    PROPERTY_ByOutstanding();

    setIsLoading(false);
    //console.log("properties ==>> ", properties);
    
  }, []);

  if (isLoading) return <Loader />;

  if (properties.length > 0 ) {
    return (
      <>
        <StyledToolbar>
            <Typography
            variant="h6"
            component="h2"

        >
            <Box>
                <img src={logo} alt="Logo" width="100%" height="100%"/>
            </Box>
            </Typography>
            <Box sx={{display: { xs: 'block', sm: 'none' } }}>
            </Box>
        </StyledToolbar>

        <Grid container spacing={gridSpacing}
          sx={{backgroundColor:"white"}}
        >
          {properties.map((property, index) => (
            <Grid key={ index } item xs={12} sm={6}>
              <PropertyCard property={ property } />
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid container spacing={gridSpacing}
          sx={{backgroundColor:"white"}}
        >
          <Grid item xs={12}>
              <Grid container alignContent={"center"} justifyContent={"center"} alignItems={'center'}>
                <Typography variant='h3'>No se ha encontrado la información correspondiente.</Typography>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  }
}
