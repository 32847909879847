import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import ReactHtmlParser from 'html-react-parser';

// material-ui
import { 
} from '@mui/material';


/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

// ==============================|| SAMPLE PAGE ||============================== //

export default function GetMeta({ page = 1, data = {}, url = "" }) {
  const [isRun, setIsRun] = useState(false);
  const [error, setError] = useState(null);
  
  const [meta, setMeta] = useState({
    title: "",
    subtitle: "",
    content: ""
  });

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");

  async function fetchData() {
    try {
      SiteService.METATAG_metaTagGetByID(page)
      .then ((res) => { 
        /*
        console.log("***************************************************");
        console.log("res => ", res);
        console.log("***************************************************");
        */
        setMeta(res.datos.data[0]) 
      });
      
    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setError(errorMessage);
      console.log(errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {

    if( meta.enabled ) {
      if (meta.title == null || meta.title === "") {
        setTitle("");
      } else {
        setTitle(meta.title);
      }
      if (meta.description == null || meta.description === "") {
        setDescription("");
      } else {
        setDescription(meta.description);
      }
      if (meta.content == null) {
        setContent("");
      } else {
        switch(page) {
          case 1: // HOME
            setContent(ReactHtmlParser(meta.content));
            break;
          case 2: // PROPERTY
            setContent(ReactHtmlParser(meta.content));
          break;
          case 3: // PROPERTY DETAIL
            setContent(ReactHtmlParser(getProperty(meta.content)));
            break;
          case 4: // ABOUT UT
            setContent(ReactHtmlParser(meta.content));
          break;
          case 5: // FAQ
            setContent(ReactHtmlParser(meta.content));
            break;
          case 6: // CONTACT
            setContent(ReactHtmlParser(meta.content));
            break;
          default:
            break;
        }
      }
      setIsRun(true);
    } 
    
  }, [meta]);

  
  const getProperty = (oldContent) => {
    let posI = 0;
    let posF = 0;
    let field = "";
    let newContent = oldContent;

    posI = newContent.search("_@");
    posF = newContent.search("@_");

    /*
    console.log("***************************************************");
    console.log("url           => ", url);
    console.log("data          => ", data);
    console.log("newContent    => ", newContent);
    console.log("***************************************************");
    console.log("posI => ", posI);
    console.log("posF => ", posF);
    console.log("***************************************************");
    */
    //_@site_title@_ : Título del Sitio<br>
    //_@site_description@_ : Descripción del Sitio<br>    
    
    while ( posI > 0) {
      field = newContent.substring(posI+2, posF);
      switch(field) {
        case "url": // CURRENT URL 
          newContent = newContent.substring(0, posI) + '"' + url + '"' + newContent.substring(posF+2);
          break;
        case "country": //  COUNTRY
          newContent = newContent.substring(0, posI) + '"' + data['country'].name + '"' + newContent.substring(posF+2);
          break;
        case "state": //  STATE
          newContent = newContent.substring(0, posI) + '"' + data['state'].name + '"' + newContent.substring(posF+2);
          break;
        case "city": //  CITY
          newContent = newContent.substring(0, posI) + '"' + data['city'].name + '"' + newContent.substring(posF+2);
          break;
        case "material": //  MATERIAL
          newContent = newContent.substring(0, posI) + '"' + data['material'].seo + '"' + newContent.substring(posF+2);
          break;
        case "total_mts2": //  TOTAL METERS 3
          newContent = newContent.substring(0, posI) + '"' + data['total_mts3'] + '"' + newContent.substring(posF+2);
          break;
        case "commercialoperation": //  COMMERCIAL OPERATION
          newContent = newContent.substring(0, posI) + '"' + data['commercialoperation'].seo + '"' + newContent.substring(posF+2);
          break;
        case "propertytype": //  PROPERTY TYPE
          newContent = newContent.substring(0, posI) + '"' + data['propertytype'].seo + '"' + newContent.substring(posF+2);
          break;
        case "site_title":
            newContent = newContent.substring(0, posI) + '"' + title + '"' + newContent.substring(posF+2);
            break;
        case "site_description":
              newContent = newContent.substring(0, posI) + '"' + description + '"' + newContent.substring(posF+2);
              break;
        default:
          newContent = newContent.substring(0, posI) + '"' + data[field] + '"' + newContent.substring(posF+2);
          break;
      }

      /*
      console.log("***************************************************");
      console.log("posI          => ", posI);
      console.log("posF          => ", posF);
      console.log("field         => ", field);
      console.log( field + " => ", data[field]);
      console.log("newContent    => ", newContent);
      console.log("***************************************************");
      */
      posI = newContent.search("_@");
      posF = newContent.search("@_");
      field = "";
    }

    /*    
    console.log("***************************************************");
    console.log("***************************************************");
    console.log("content    => ", content);
    console.log("***************************************************");
    console.log("***************************************************");
    */
    return newContent;
  }



//  if (isLoading) return <Loader />;
  if (error) return error;
  
  if (isRun) return (
    <>
      { ( title !== "")  &&
        <Helmet prioritizeSeoTags>
          <title>{ title }</title>
        </Helmet>
      }
      { ( description !== "")  &&
        <Helmet prioritizeSeoTags>
          <meta name="description" content={ description } />
        </Helmet>
      }
      { ( content !== "")  &&
        <Helmet prioritizeSeoTags>
        { content }
        </Helmet>
      }
    </>
  );
}
