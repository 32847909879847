import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

import logo from '../assets/images/buscatrasterologo_confondo.webp';

export default function GetNotFound() {
  const theme = useTheme();
  const [isHome, setIsHome] = useState(false);
  
  const handleButton = () => {
    setIsHome(true);
  };

  const isEmpty = (value) => {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }
  
  if(isHome) {
    return  <Navigate to="/" />
  }

  return (
    <Grid container spacing={gridSpacing} align="center"  sx={{ mt: theme.spacing(5), mb: theme.spacing(5) }}>
      <Grid item xs={12}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography variant="h3">
              ¡UPS! ¿Estás perdido?
            </Typography>
            </CardContent>
          <CardMedia
            sx={{ height: 250 }}
            image={ logo }
            title="green iguana"
          />
          <CardActions>
            <Tooltip title="Volver al Home" align="center">
                <Button aria-label="home" variant="contained" onClick={ handleButton }><WarehouseOutlinedIcon fontSize='large'/></Button>
            </Tooltip>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12}>

      </Grid>
    </Grid>
  );
}
