import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

// third party
import { Provider } from 'react-redux';

import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';


// assets
import './assets/scss/style.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>    
    </Provider>
  </React.StrictMode>
);