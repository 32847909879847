import React from 'react';
import Cookie from 'js-cookie';

import {
  Box,
  Dialog,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import Form from './Form';

// ==============================|| SAMPLE PAGE ||============================== //

const GetRegister = ( { open = false, onClose } ) => {
  const theme = useTheme();

  const handleChangeForm = (validated, user) => {
    /*    
    console.log("***********************************************");
    console.log("validated => ", validated);
    console.log("***********************************************");
    console.log("user => ", user);
    console.log("***********************************************");
    */
    Cookie.set("__CK_registered", true, { expires: 365, path: '' });
    if (validated) {
      Cookie.set("__CK_user", JSON.stringify(user), { expires: 365, path: '' });
    } else {
      Cookie.remove("__CK_user", user, { path: '' });
    }
    onClose(true, user);
  };
  
  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog
      open={ open }
      onClose={ handleClose }
    >
      <Box sx={{ m: theme.spacing(4) }}>
        <Grid container>
          <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>¡Completá tus datos y encontrá tu próximo Trastero!</Typography>          
              <Typography variant="subtitle1" gutterBottom color={"grey"}>Una vez que estés registrado podrás tomar contacto por todos los medios.</Typography>          
              <Divider/>
            </Grid>
          <Grid item xs={12}>
            <Form 
              onChange = { handleChangeForm }
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default GetRegister