import React from 'react'
import Details from '../components/Details';
import GetMeta from '../components/GetMeta';

const Contact = () => {
  return (
    <>
      <Details />
      <GetMeta page={6} />
    </>
  )
}

export default Contact