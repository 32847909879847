
import axios from "axios";
import { store } from '../store';

const callApi = async (method = "POST", call, data = {}, isImage = false) => {
    const parameters = store.getState().parameters;

//    baseURL: "http://localhost:8000/" + "api",
    
    const httpConfig = {
      baseURL: parameters.parameters.httpConfig.backEndURL + "api",
      headers: {
        "Content-type": (isImage ? "multipart/form-data":"application/json"),
      }
    }

    const http = axios.create(httpConfig);
    let response;
    try {
      switch(method) {
        case 'GET':
          response = await http.get(call, data);
          break;
        case 'POST':
          response = await http.post(call, data);
          break;
        case 'PUT':
          response = await http.put(call, data);
          break;
        case 'DELETE':
          response = await http.delete(call, data);
          break;
        case 'PATCH':
          break;
        default:
          return {
            status: false,
            datos: {}
          };
      }
      return {
        status: true,
        datos: response.data
      };

    } catch(error) {
      return {
        status: false,
        datos: error
      };
    }
};

export default callApi;