import React from 'react'
import GetNotFound from '../components/GetNotFound';

const NotFound = () => {
  return (
    <>
      <GetNotFound />
    </>
  )
}

export default NotFound