import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Pagination,
  Stack,
  Typography
} from '@mui/material';

import Title from './Title';
import Paragraph from './Paragraph';
import Cuestionary from './Cuestionary';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

import Loader from './Loader/Loader';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

const firstIndex = 0;

export default function GetFaq() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  const [faqs, setFaqs] = useState([]);

  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(faqs.slice(0, pageSize));
  }, [pageSize]);

  const handleChange = (event, value) => {
    setPage(value);
    setData(faqs.slice(firstIndex + pageSize * (value - 1), pageSize * value));
  };

  //Change width
  const changeWidth = (e) => {
    setPageSize(parseInt(e.target.value, 10));
  };

  const FAQ_List = async () => {
    
    try {
      const response = await SiteService.FAQ_List();
      setFaqs(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }
  }

  useEffect(() => {
    FAQ_List();
    setData(faqs.slice(firstIndex, pageSize));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setData(faqs.slice(firstIndex, pageSize));

    setIsLoading(false);
  }, [faqs]);

  if (isLoading) return <Loader />;

  if (faqs.length > 0 && !isLoading) {
    return (
    <>
      <Stack 
      component='section'
      direction="column"
      justifyContent= 'center'
      alignItems='center'
      sx={{
          py: 10,
          px: 2,
      }}
      >
        <Title 
          text={
              'Preguntas Frecuentes'
              } 
          textAlign={'center'}
        />
        <Paragraph 
          text={
              'En esta área del sitio vamos agregando en el transcurso del tiempo en base al volumen de consultas recibidas, una lista de preguntas frecuentes, buscando de esta manera, evacuar la mayor cantidad de inquietudes que puedan aparecer.'
          }
          maxWidth = {'sm'}
          mx={0}
          textAlign={'center'}
        />        
        <MyPagination data={ faqs } />
      </Stack>    
    </>
    );
  }  else {
    return (
      <>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
              <Grid container alignContent={"center"} justifyContent={"center"} alignItems={'center'}>
                <Typography variant='h3'>No se ha encontrado la información correspondiente.</Typography>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  }
}


const MyPagination = ({ data }) => {
  const theme = useTheme();
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Puedes ajustar este valor según sea necesario.

  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
      
    setCurrentPage(newPage);
  };

  // Calcular los índices para paginar los datos
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <>
      {/* Mapea los elementos actuales */}
        <Grid container spacing={gridSpacing}>
          {currentItems.map((faq) => (
            <Grid key={ faq.id } item xs={12} sx={{ mt: theme.spacing(3), mb: theme.spacing(1), ml: theme.spacing(3), mr: theme.spacing(3) }}>
              <Cuestionary id={faq.id} question={faq.question} answer={faq.answer} />
            </Grid>
        ))}
      </Grid>
      {/* Paginación */}
      <Box display={'flex'} justifyContent={'center'} alignItems={'flex-center'} sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}>
        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </>
  );
};
