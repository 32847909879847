import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Pagination,
  Typography
} from '@mui/material';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';
import Loader from '../components/Loader/Loader';

import PropertyCard from './PropertyCard';
import { store } from '../store';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

export default function Result(props) {
  const theme = useTheme();

  const {isChangeResult, setIsChangeResult} = props;
  const search = store.getState().currentSession.search;
  const [isLoading, setIsLoading] = useState(true);

  const [properties, setProperties] = useState([]);

  const PROPERTY_BySearchCriteria = async (search) => {
    
    if (isChangeResult) {
      try {
        const response = await SiteService.PROPERTY_BySearchCriteria(search);
        setProperties(response.datos.data);

        setIsChangeResult(false);
      } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
      }
    }

  }

  useEffect(() => {
    PROPERTY_BySearchCriteria(search);
    setIsLoading(false);
    
  }, [isChangeResult]);

  if (isLoading) return <Loader />;

  if (properties.length > 0 ) {
    return (
      <MyPagination data={properties} />
    );
  } else {
    return (
      <>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
              <Grid container alignContent={"center"} justifyContent={"center"} alignItems={'center'}>
                <Typography variant='h3'>No se ha encontrado la información correspondiente.</Typography>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  }
}

const MyPagination = ({ data }) => {
  const theme = useTheme();
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Puedes ajustar este valor según sea necesario.

  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
      
    setCurrentPage(newPage);
  };

  // Calcular los índices para paginar los datos
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <>
      {/* Mapea los elementos actuales */}
      <Grid container spacing={gridSpacing}>
        {currentItems.map((property) => (
          <Grid key={ property.id } item xs={12}>
            <Grid container>
              <PropertyCard property={ property } />
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Paginación */}
      <Box display={'flex'} justifyContent={'center'} alignItems={'flex-center'} sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}>
        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </>
  );
};
